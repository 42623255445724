/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/types/bte/'], ['en', 'https://www.hear.com/hearing-aids/types/bte/'], ['en-US', 'https://www.hear.com/hearing-aids/types/bte/'], ['en-CA', 'https://ca.hear.com/hearing-aids/types/bte/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "unobtrusive-and-efficient",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unobtrusive-and-efficient",
    "aria-label": "unobtrusive and efficient permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unobtrusive and efficient"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Behind-the-ear hearing aids (BTE) are the best known and most frequently selected models. The hearing aids are very small, light, and can be worn almost invisibly behind the ear. Behind-the-ear hearing aids are suitable for ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "almost all types of hearing loss."), " Around 90% of Canadian hearing aid wearers choose behind-the-ear hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "BTEs are available in various colors to match the wearer’s hair and skin color or to express a his or her personal style. They can also come with additional functions. Generally, the larger the hearing aid, the higher the efficiency."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While small BTE hearing aids are adequate for most types of hearing impairment, severe hearing loss requires larger behind-the-ear hearing aids. In these instances, modern hearing aids, such as the ReSound LiNX, offer both a lean design as well as the necessary high amplification power."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "BTE hearing aids come in two basic versions:")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bte-hearing-aids-with-external-receivers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bte-hearing-aids-with-external-receivers",
    "aria-label": "bte hearing aids with external receivers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "BTE hearing aids with external receivers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/bte-ha-system.jpg",
    alt: "Behind the ear hearing system with external receiver",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These over ear hearing aids are very popular with first-time buyers. They are the most unobtrusive form of BTE models and are characterized by a high degree of wearing comfort. The amplified signal is transferred to the ‘receiver’ through a thin cable. The receiver is located in the ear canal just in front of the eardrum, i.e. ‘externally’ (outside the hearing aid housing). A small fixation dome or an individual ear mold supports the receiver’s position."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This design delivers a clear and natural quality of speech – your own voice and ambient sounds are perceived naturally again. An advantage is that the ear is only closed partially and the ear canal remains pleasantly free and open, thereby permitting good ventilation. The fact that a thin audio tube creates a direct connection between the hearing aid and eardrum also ensures a particularly high quality of sound. This model, also called “open supply,” can be used in slight and moderate cases of hearing loss."), "\n", React.createElement(ButtonCta, {
    copy: "Try hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bte-hearing-aids-with-sound-tube",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bte-hearing-aids-with-sound-tube",
    "aria-label": "bte hearing aids with sound tube permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "BTE hearing aids with sound tube"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/BTE_with_sound_tube-frame.jpg",
    alt: "Behind the ear hearing system with external receiver",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "These hearing aids"), " are commonly used for more severe hearing loss. They offer pleasant wearing comfort and provide very high performance. The sound travels through a tube into an individual ear mold, which is custom fitted to the wearer’s ear. The housing, larger when compared to the BTE hearing aid with an external receiver, contains a more efficient amplifier and a ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries/",
    className: "c-md-a"
  }, "larger battery"), ". These days, extensive combinations of color and form are part of the modern, unobtrusive hearing systems, even in this high-performance range."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In comparison to in-the-ear hearing aids, behind-the-ear devices tend to offer greater wearing comfort, better natural speech and sound perception, as well as easier operation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
